.nav__bar {
    padding: 0;
    max-width: 100%;

    @media (min-width: 768px) {
        // padding: 0 1rem;
    }
}
.navbar__wrapper {
    padding: 0;
    margin: 0.5rem auto;

    @media (min-width: 768px) {
        max-width: 80vw;
        padding: 0;
    }
}
.nav__sticky {
    background-color: $colour-primary;

    @media (min-width: 768px) {
        padding: 0 1rem;
    }
    z-index: 1;
}

.show {
    width: 100vh !important;
}
.logo {
    font-family: $font-logo;
    padding-left: 0.75rem;
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 0;
    // color: $colour-ui-accent !important;

    @media (min-width: 768px) {
        padding-left: 0;
    }

}
h1 {
    margin-bottom: 0;
    font-weight: 300;
}
.navbar-light .navbar-brand {
    color: $colour-ui-accent;
    border-color: none;
}
.navbar-light .navbar-toggler {
    border: none;
}
.navbar-light .navbar-nav .nav-link {
    color: $colour-secondary;
}
a {
    color: $colour-primary;
    text-decoration: none;
    font-weight: 300;
    font-family: $font-heading;
}
a:hover, a:active {
    color: $colour-secondary;
}
ul {
    padding-left: 0 !important;
    list-style: none;
    li {
        font-weight: 400;
        font-family: $font-primary;
        margin-bottom: 1rem;
        padding-left: .5rem;
    }
}
.navbar-nav {
    padding: 0 1rem;

    @media (min-width: 992px) {
        width: 100%;
        display: flex;
        justify-content: end;
    }
}
.navbar-collapse {
    padding: 1rem 0;
}
.nav-link {
    // color: $colour-nav;

    @media (min-width: 992px) {
        font-size: .8rem;
        font-weight: 500;
        padding: 0;
        margin: .2rem;
    }
}
.active {
    color: $colour-ui-primary !important;
    font-weight: 500;
}
// .navbar-toggler-icon, .navbar-toggler, .navbar-collapse {
//     // width: 40px;
//     color: $colour-ui-accent;
// }
.button__wrapper {
    text-align: center;
    display: flexbox;
    flex-wrap: nowrap;
    padding: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.button {
    background: none;
    border: 2.5px solid;
    border-color: $colour-ui-primary;
    text-decoration: none;
    padding: .5 1rem;
    width: 60vw;
    border-radius: 5px;
    transition: all 0.4s;

    @media (min-width: 768px) {
    width: 50%;
    flex-wrap: nowrap;
    }
}
.button__link {
    text-decoration: none;
    text-transform: none;
    color: $colour-ui-primary;
    font-weight: 500;
}
.button:visited {
    box-shadow: $shadow;
    background: $colour-ui-primary;
    border: none !important;
}
.btn-primary:hover {
    box-shadow: $shadow;
    background: $colour-ui-primary;
    color: $colour-primary;
    border: none !important;
}
.button:hover {
    box-shadow: $shadow;
    border: none !important;
}
.button:active {
    box-shadow: $shadow;
    border: none !important;
}

.btn-primary:focus, .btn:focus, .btn:active {
    background-color: $colour-ui-primary;
    color: $colour-primary;
    outline: 0;
    outline-color: transparent;
    outline-width: 0;
    outline-style: none;
    box-shadow: 0 0 0 0 rgba(0,123,255,0);
    border: none !important;
}
.button__link:hover, .button__link:focus, .button__link:active {
    color: $colour-primary;
    font-weight: 500;
}
// .navbar-toggler-icon, .navbar-light, .navbar-toggler {
//     border: none !important;
//     box-shadow: none;

// }
.navbar-toggler:visited, .navbar-toggler:hover, .navbar-toggler:focus, .navbar-toggler:active {
    border: none !important;
    box-shadow: none;
    border-color: none;
}