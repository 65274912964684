h2 {
    font-family: $font-heading;
    font-weight: 300;
    color: $colour-ui-accent;
}
h2 {
    font-size: 1.4rem;
}

.heading {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}
.component__title {
    color: $colour-ui-primary !important;
    font-weight: 500;
}
p {
    line-height: 2rem;
    padding: 0.5rem 0;
    margin-bottom: 0;
    // font-weight: 300;
}

.mini__heading {
    text-transform: uppercase;
    // color: $colour-ui-accent;
}

.text__intro, .quote {
    font-weight: 400;
    color: $colour-ui-accent;
    font-size: 1.2rem;
    
}

.record {
    padding-top: 0;
    font-size: 1rem;
}
.cite {
    margin: 4rem;
}

.bold {
    font-weight: 800;
}

// .quote-storest {
// margin-top: 2rem;
// }

.row_flex_text {
    margin-bottom: 1rem;
    max-width: 80%;

    @media (max-width: 481px) {
        max-width: 100%;
        }
}