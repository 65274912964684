.album__wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-content: space-around;
    margin: 1rem;
    // width: 100vw;
}
.card {
    background-color: $colour-primary;
    border: 0;
    border-radius: 2;
    padding: 10px;
    width: 200px;
    // text-align: left;
    margin-bottom: 2rem;
    margin-top: 1rem;
    background-color: white;
    box-shadow: 0px 2px 3px 6px rgba(150, 150, 150, 0.044);
}
.card-body {
    padding: 1rem 1rem 0 1rem;
    text-align: left;
}
.card__title {
    font-family: $font-heading !important;
    font-weight: 400;
    color: $colour-ui-primary;
    font-size: 1.2rem;
    // text-transform: uppercase;
    margin-bottom: 1rem;
}
.album_info {
    display: flex;
    flex-wrap: nowrap;
    // justify-content: start;
}
.album_info-item {
    padding: 0;
    padding-right: 0.4rem;
    margin-bottom: 0.5rem;
}
.album__image {
    border-top-left-radius: 2;
    border-top-right-radius: 2;
    border-bottom-left-radius: 2;
    // border-bottom-right-radius: 2;
    object-fit: contain;
}
.wrapper__icon-light {
    display: flex;
    // justify-content: start;
    align-items: flex-end;
    gap: 0,5rem;
    margin: 0.5rem 0;

    a {
        color: $colour-secondary;
    }
}

.album__para {
    text-transform: capitalize;
    padding: 0;
    // font-style: italic;
    font-weight: 500;
}

.wrapper_listen_textlinks {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-content: center;
}

.listen_list {
    padding-left: 0;
    margin-bottom: 0;
    font-weight: 300;
}

ul {
    margin-bottom: 0;
}