html,
body {
    height: 100%;
}
body {
    display: flex;   
    flex-direction: column; 
    background-color: $colour-primary;
    color: $colour-secondary;
    font-family: $font-primary;
    font-weight: 300;
    margin: 0 auto;
    font-size: 18px;
}
.container {
    overflow: hidden;
    padding: 0;
}
.wrapper {
    flex: 1 0 auto;
    margin: 0 auto;

    @media (min-width: 481px) {
        max-width: 80vw;
        // margin: 0 1rem;
        }
    
    @media (min-width: 959px) {
        max-width: 80vw; }
}
.text__content {
    margin-top: 3rem;

    @media (max-width: 481px) {
        // max-width: 80vw;
        margin-top: 1rem;
        padding: 1rem;
        }

}
.flex-column {
    margin: 0.5rem 0 0.5rem 0;
}
footer {
    flex-shrink: 0; 
    color: $colour-primary;
    background-color: $colour-secondary;
    flex-wrap: wrap;
    margin-top: 6rem;
    padding: 1rem;
    width: 100%;
    text-align: center;

    p {
        font-size: 1rem;
    }

    .footer__link {
        font-size: 0.8rem;
        color: $colour-primary !important;
        font-weight: 300 !important;
    }

    .footer__icon {
        margin: 1rem;
    }
    @media (min-width: 768px) {
        margin-top: 4rem;
        // margin: 0 auto;
    }
    .footer__desktop__wrapper {
        @media (min-width: 768px) {
            display: flex;
            flex-direction: row;
            padding-left: 3rem;
        }
    }
    .footer__desktop {
        @media (min-width: 768px) {
            // max-width: 50%;
            flex-wrap: wrap;
        }
    }
    .desktop__link {
        @media (min-width: 768px) {
            display: inline;
            padding: 0 .4rem;
        }
    }
    .footer__nav {
        margin-bottom: 2.5rem;
        @media (min-width: 768px) {
            padding-top: .8rem;
            text-align: right;
        }
    }

}
.iframe__container {
    padding: 2rem 0;
    @media (max-width: 481px) {
    // max-width: 80vw;
    padding: 1rem;
    }

}

iframe {
    @media (min-width: 768px) {
        margin: 1.5rem 0;
    }
}

.hero__container {
    // margin-bottom: 1rem;
}

hr {
    margin-bottom: 0;
    margin-top: 2rem;
}

.quote__wrapper {
    margin: 0 auto;
    text-align: center;
    max-width: 60%;

    @media (max-width: 481px) {
        // max-width: 80vw;
        max-width: 90%;
        }
}

.cite__wrapper {
    // margin-top: 1.5rem;
}

.home__iframe {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.top__divider {
    margin-top: 1rem;
}

.flex_row_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;

    @media (max-width: 481px) {
        flex-direction: column;
        max-width: 100%;

        }
}

.flex_row_child {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: top;
    width: 50vw;

    @media (max-width: 481px) {
        margin-right: 0;
        width: 100%;
        margin-right: 0;
        width: 100vw;
        }
}

.row_margin {
    max-width: 50vw;

    @media (max-width: 481px) {
        margin: 1rem;
        max-width: 100vw;
        }

}
