
.hero__image {
    max-width: 100%;
    // height: 80vh;
    object-fit: cover;
}

.image {
    max-width: 100%;
    margin-bottom: 2rem;
}

.flex_contained_image {
    // width: 100%;
    max-width: 50vw;
    object-fit: cover;
    max-height: 800px;

    @media (max-width: 481px) {
        flex-direction: column;
        width: 100% !important;
        max-width: 100vw;
        
        }
}

.flex_child_image {
    justify-content: start;

    @media (max-width: 481px) {
        width: 100% !important;
        
        }
}

// .borde {
//     // width: 30px;
//     // background-repeat: repeat-y;
//     margin: 0;
//     padding: 0;
//     height: 20px;
//     overflow: hidden;
// }

// .container__borde {
//     max-width: 100%;
//     margin: 0;
//     padding: 0;
//     background-image: "../../images/lang-borde-lys-beige.png";
//     overflow: hidden;
// }