//colours
$colour-primary:  #fafafa;
//  #212226
$colour-nav:  #6d6b6c;
$colour-secondary: #212226;
$colour-ui-primary: #007a33;
// #362227, #007a33
$colour-ui-accent: #362227;
// $colour-ui-navbar: #f2edef;
$colour-ui-shadow: rgba(0, 0, 0, 0.169);

$shadow: 6px 10px 11px 0px $colour-ui-shadow;

//Typography
$font-primary: "basic-sans", sans-serif;
//Available in 200, 400, 600
$font-heading: "jaf-domus-titling-web", sans-serif;
//Available in 200, 300, 400
$font-logo: "interstate", sans-serif;
// Available in 300, 400,
// $font-test: "bilo", sans-serif;
// $font-logo: "auster", sans-serif;